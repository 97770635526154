//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-900:_3304,_1776,_2420,_1832,_9036,_2656,_4476,_6616;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-900')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-900', "_3304,_1776,_2420,_1832,_9036,_2656,_4476,_6616");
        }
      }catch (ex) {
        console.error(ex);
      }